import { render, staticRenderFns } from "./square-action-box.vue?vue&type=template&id=0e1251fd&"
import script from "./square-action-box.vue?vue&type=script&lang=js&"
export * from "./square-action-box.vue?vue&type=script&lang=js&"
import style0 from "./square-action-box.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports